<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <div class="header">
      <img alt="美美去水印" src="../assets/logo.png" />
      <span>美美去水印</span>
    </div>
    <div class="home-con">
      <p class="title">小程序</p>
      <img alt="美美去水印" src="../assets/mp.jpg" class="common-img" />
      <p class="title">安卓下载</p>
      <p class="c-des">1.扫码下载</p>
      <img alt="美美去水印" src="../assets/meimeiapk.png" class="common-img" />
      <p class="c-des">2.链接下载(请用系统默认浏览器打开)</p>
      <p class="download-link">
        <a href="https://www.meimeiqushuiyin.cn/app/meimei.apk">美美去水印</a>
      </p>
    </div>
    <div class="footer">
      Copyright © 2020 - 2024
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >蜀ICP备2022012189号-1</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.header {
  background-color: #ff827e;
  text-align: left;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #fff;
  > img {
    height: 40px;
    margin-right: 10px;
  }
}
.title {
  text-align: left;
  font-weight: bold;
}
.home-con {
  padding: 15px;
  box-sizing: border-box;
  text-align: left;
  flex: 1;
  .common-img {
    width: 200px;
    margin-top: 15px;
  }
  .c-des {
    margin-top: 10px;
  }
  .download-link {
    color: #ff827e;
  }
}
.footer {
  font-size: 14px;
  padding: 10px 0;
}
</style>
